import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/design_system/button": [6],
		"/design_system/markdown": [7],
		"/folders": [8],
		"/folders/[folderid]": [9],
		"/onboarding": [10],
		"/privacy_policy": [11],
		"/settings": [12],
		"/terms_of_service": [13],
		"/third_party_agreement": [14],
		"/waitlist": [15],
		"/workflows": [16],
		"/workflows/[workflowid]": [17,[2]],
		"/workflows/[workflowid]/checks": [18,[2]],
		"/workflows/[workflowid]/checks/[checkid]": [19,[2]],
		"/workflows/[workflowid]/documents": [20,[2]],
		"/workflows/[workflowid]/files": [21,[2]],
		"/workflows/[workflowid]/files/[fileid]": [22,[2]],
		"/workflows/[workflowid]/reports": [23,[2]],
		"/workflows/[workflowid]/settings": [24,[2]],
		"/workflows/[workflowid]/table": [25,[2]],
		"/workspace": [26],
		"/workspace/[id]": [27,[3]],
		"/workspace/[id]/conversation/new-research": [30,[3]],
		"/workspace/[id]/conversation/new": [29,[3]],
		"/workspace/[id]/conversation/[conversationid]": [28,[3]],
		"/workspace/[id]/file/[fileid]": [31,[3]],
		"/workspace/[id]/library/conversations": [32,[3,4]],
		"/workspace/[id]/library/documents": [33,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';